import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"

function Select(props) {
  const {
    id,
    name,
    label,
    defaultValue,
    children,
    options,
    className,
    labelClassName,
    controlClassName,
    hint,
    noLabel,
    onChange,
    keyField,
    labelField,
    saveDefaultValue,
  } = props
  const [showHint, setShowHint] = useState(false)

  const toggleHint = () => setShowHint(prev => !prev)

  const handleChange = e => {
    if (onChange && typeof onChange === "function") {
      onChange(e.target)
    }
  }

  useEffect(() => {
    if (saveDefaultValue) onChange({ name, value: defaultValue })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderHint = () =>
    showHint ? (
      <div className="Form-hint">
        {hint}.{" "}
        <span
          tabIndex={0}
          role="button"
          onClick={toggleHint}
          onKeyDown={toggleHint}
          className="Form-hintClose"
        >
          [X] Cerrar
        </span>
      </div>
    ) : null

  const renderHintIcon = () =>
    hint ? (
      <span
        tabIndex={0}
        role="button"
        onClick={toggleHint}
        onKeyDown={toggleHint}
        className="Form-hintIcon"
      >
        {" "}
        [?]
      </span>
    ) : null

  const renderOptions = () =>
    children
      ? children
      : options.map((opt, index) => (
          <option key={`${id}-opt-${index}`} value={opt[keyField]}>
            {opt[labelField]}
          </option>
        ))

  const renderLabel = () =>
    noLabel ? null : (
      <label className={`Form-label ${labelClassName}`} htmlFor={id}>
        {label}
        {renderHintIcon()}:
      </label>
    )

  return (
    <Fragment>
      {renderHint()}
      <div className={`Form-item ${className}`}>
        {renderLabel()}

        <select
          name={name}
          id={id}
          className={`Form-control ${controlClassName}`}
          defaultValue={defaultValue}
          onChange={handleChange}
        >
          {renderOptions()}
        </select>
      </div>
    </Fragment>
  )
}

Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  labelClassName: PropTypes.string,
  controlClassName: PropTypes.string,
  labelField: PropTypes.string,
  keyField: PropTypes.string,
  children: PropTypes.node,
  options: PropTypes.array,
  hint: PropTypes.string,
  noLabel: PropTypes.bool,
  saveDefaultValue: PropTypes.bool,
}

Select.defaultProps = {
  options: [],
  className: "",
  labelClassName: "",
  controlClassName: "",
  keyField: "value",
  labelField: "label",
}

export default Select

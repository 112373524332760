import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"

function Radio(props) {
  const { id, name, options, defaultValue, onChange } = props
  const [state, setState] = useState()

  const handleChangeValue = ({ target }) => {
    const { value } = target
    setState(parseInt(value || 0))

    if (typeof onChange === "function") {
      onChange({ value: parseInt(value || 0), name })
    }
  }

  useEffect(() => {
    setState(defaultValue)
  }, [defaultValue])

  const renderRadios = () =>
    options.map((opt, index) => {
      return (
        <div key={`${name}-opt-${index}`}>
          <label className="Form-label radio">
            <input
              checked={opt.value === state}
              disabled={opt.disabled}
              type="radio"
              id={id}
              name={name}
              value={opt.value}
              onChange={handleChangeValue}
            />
            {opt.label}
          </label>
        </div>
      )
    })

  return <Fragment>{renderRadios()}</Fragment>
}

Radio.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onChange: PropTypes.string,
  defaultValue: PropTypes.number,
}

Radio.defaultProps = {
  options: [],
}

export default Radio

import React, { Fragment, useState } from "react"
// import PropTypes from "prop-types"
import Layout from "../../components/Layout"
import Seo from "../../components/TitlePage"
import Hero from "../../components/Hero"
import Breadcrumb from "../../components/Breadcrumb"
import TitleBlock from "../../components/TitleBlock"
import { Helmet } from "react-helmet"
import RadioButton from "../../components/Form/Radio"
import Input from "../../components/Form/Input"
import Checkbox from "../../components/Form/Checkbox"
import Select from "../../components/Form/Select"
import { useAxiosClient } from "../../service/useAxiosClient"
import Message from "../../components/Message"

const radios = [
  { label: "Por correo postal", value: 0 },
  { label: "Por email (PDF)", value: 1 },
]

const MagazineSuscription = ({ pageContext, params, id: devId, location }) => {
  const category = pageContext?.id || params?.id || devId
  const [formData, setFormData] = useState({
    receive_by_email: 0,
  })
  const [statusCode, setStatusCode] = useState(null)
  const [check, setCheck] = useState(false)
  const path = [
    { label: "Inicio", to: "/" },
    { label: "Revista Asesores Financieros EFPA" },
  ]
  const [{ data: countries }] = useAxiosClient({
    url: `/geo/countries/`,
    params: {
      nitems: 1000,
    },
  })
  const [, triggerSaveData] = useAxiosClient(
    {},
    {
      manual: true,
    }
  )

  const handleChange = ({ value, name }) =>
    setFormData(prev => ({ ...prev, [name]: value }))

  const handleCheck = () => setCheck(prev => !prev)

  const handleSubmit = async e => {
    e.preventDefault()
    if (!check && statusCode) return setStatusCode("check")
    if (
      formData.receive_by_email &&
      (!formData.name || !formData.enterprise || !formData.email)
    )
      return setStatusCode("required")
    if (
      !formData.receive_by_email &&
      (!formData.name ||
        !formData.enterprise ||
        !formData.address ||
        !formData.postal_code ||
        !formData.city ||
        !formData.province ||
        !formData.country)
    )
      return setStatusCode("required")
    try {
      let data = { ...formData }
      if (category) data["category"] = category
      await triggerSaveData({
        url: `/magazine-guests/web/`,
        method: "post",
        data,
      })
      setStatusCode(201)
    } catch ({ response: { status } }) {
      setStatusCode(status)
    }
  }

  const renderMessage = () => {
    if (!statusCode) return

    let message = ""
    let type = ""
    if (statusCode === "check") {
      message = `Debe aceptar la política de protección de datos y advertencias legales.`
      type = "error"
    }
    if (statusCode === "required") {
      message = "Por favor, rellene todos los campos obligatorios."
      type = "error"
    }
    if (statusCode === 201 && !message) {
      message = "Sus datos se han guardado correctamente."
      type = "success"
    }
    if (statusCode === 400 && !message) {
      message = "Ya ésta suscrito a la revista."
      type = "success"
    }
    if (statusCode > 400 && !message) {
      message =
        "Ha ocurrido un error durante el envio. Por favor, inténtenlo más tarde o póngase en contacto con nosotros por otros medios. Muchas gracias."
      type = "error"
    }
    return <Message text={message} type={type} />
  }

  const getClassNameWithValid = field =>
    !formData[field] && statusCode ? "Contact-input danger" : "Contact-input"

  const renderForm = () =>
    statusCode !== 201 &&
    statusCode !== 400 && (
      <Fragment>
        <span className="ActivityTab-newsRelated">¿Cómo desea recibirla?</span>
        <RadioButton
          options={radios}
          name="receive_by_email"
          onChange={handleChange}
          defaultValue={formData["receive_by_email"]}
        />
        <form onSubmit={handleSubmit}>
          <div className="Contact-wrapper">
            <Input
              controlClassName={getClassNameWithValid("name")}
              className="Contact-label toRight"
              label="Nombre del destinatario (*)"
              name="name"
              value={formData["name"]}
              onChange={handleChange}
            />
            <Input
              controlClassName={getClassNameWithValid("enterprise")}
              className="Contact-label toRight"
              label="Empresa (*)"
              name="enterprise"
              value={formData["enterprise"]}
              onChange={handleChange}
            />
            <Input
              controlClassName="Contact-input"
              className="Contact-label toRight"
              label="Cargo"
              name="professional_position"
              value={formData["professional_position"]}
              onChange={handleChange}
            />
            {!formData.receive_by_email && (
              <Fragment>
                <Input
                  controlClassName={getClassNameWithValid("address")}
                  className="Contact-label toRight"
                  label="Domicilio (*)"
                  name="address"
                  value={formData["address"]}
                  onChange={handleChange}
                />
                <Input
                  controlClassName={getClassNameWithValid("postal_code")}
                  className="Contact-label toRight"
                  label="Código postal (*)"
                  name="postal_code"
                  value={formData["postal_code"]}
                  onChange={handleChange}
                />
                <Input
                  controlClassName={getClassNameWithValid("city")}
                  className="Contact-label toRight"
                  label="Localidad (*)"
                  name="city"
                  value={formData["city"]}
                  onChange={handleChange}
                />
                <Input
                  controlClassName={getClassNameWithValid("province")}
                  className="Contact-label toRight"
                  label="Provincia (*)"
                  name="province"
                  value={formData["province"]}
                  onChange={handleChange}
                />
                {countries && (
                  <Select
                    options={countries}
                    labelField="name"
                    keyField="pk"
                    label="País (*)"
                    controlClassName={getClassNameWithValid("country")}
                    labelClassName="Contact-label toRight"
                    name="country"
                    defaultValue="89"
                    saveDefaultValue
                    onChange={handleChange}
                  />
                )}
              </Fragment>
            )}
            <Input
              controlClassName={
                !formData.receive_by_email
                  ? "Contact-input"
                  : getClassNameWithValid("email")
              }
              className="Contact-label toRight"
              label={!formData.receive_by_email ? "E-mail" : "E-mail (*)"}
              name="email"
              value={formData["email"]}
              onChange={handleChange}
            />
            {!formData.receive_by_email && (
              <Input
                controlClassName="Contact-input"
                className="Contact-label toRight"
                label="Teléfono"
                name="phone"
                value={formData["phone"]}
                onChange={handleChange}
              />
            )}
          </div>
          <Checkbox onChange={handleCheck}>
            He leído y acepto la{" "}
            <a href="/politica-privacidad" target="_blank">
              política de protección de datos
            </a>{" "}
            y advertencias legales aplicables a esta comunicación.
          </Checkbox>
          <button type="submit" className="Contact-btn">
            Enviar
          </button>
        </form>
      </Fragment>
    )

  return (
    <Layout>
      <Helmet>
        <script
          type="text/javascript"
          src="https://www.google.com/recaptcha/api.js"
          id="google-recaptcha-js"
        ></script>
        <script type="text/javascript" id="wpcf7-recaptcha-js-extra" />
      </Helmet>
      <Seo title="Revista Asesores Financieros EFPA" url={location.pathname} />
      <Hero image="/imgs/servicios-actividades-ret.jpg" />
      <div className="container950">
        <Breadcrumb path={path} />
        <TitleBlock
          title="Revista Asesores Financieros EFPA"
          icon="far fa-address-card"
          subtitle="Formulario para la suscripción trimestral a la revista Asesores Financieros EFPA:"
        />
        {renderMessage()}
        {renderForm()}
      </div>
    </Layout>
  )
}

MagazineSuscription.propTypes = {}

export default MagazineSuscription

import React from "react"
import { Router } from "@reach/router"
import MagazineSuscription from "."

const App = () => {
  return (
    <Router basepath="/suscripcion-revista">
      <MagazineSuscription path="/:id" />
      <MagazineSuscription path="/" />
    </Router>
  )
}
export default App
